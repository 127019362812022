export const partners = [{
        img: require("../images/app/partners/bonita.png"),
    },
    {
        img: require("../images/app/partners/bua.png"),
    },
    {
        img: require("../images/app/partners/caraway.png"),
    },
    {
        img: require("../images/app/partners/checkers.png"),
    },
    {
        img: require("../images/app/partners/chi.png"),
    },
    {
        img: require("../images/app/partners/cormart.png"),
    },
    {
        img: require("../images/app/partners/fanmilk.png"),
    },
    {
        img: require("../images/app/partners/mamada.png"),
    },
    {
        img: require("../images/app/partners/mcpl.png"),
    },
    {
        img: require("../images/app/partners/perfetti.png"),
    },
    {
        img: require("../images/app/partners/popcola.png"),
    },
    {
        img: require("../images/app/partners/pz.jpeg"),
    },
    {
        img: require("../images/app/partners/sonia.png"),
    },
    {
        img: require("../images/app/partners/tgi-logo.png"),
    },
    {
        img: require("../images/app/partners/wacot.png"),
    },
    {
        img: require("../images/app/partners/wasil.png"),
    },
    {
        img: require("../images/cocacola.png"),
    },
    {
        img: require("../images/unilever-logo.png"),
    },
    {
        img: require("../images/golden-penny-logo.png"),
    },
    {
        img: require("../images/promasidor-logo.png"),
    },
];