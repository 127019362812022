import React from 'react'

export default function SandaRuwangida() {
  return (
    <section className="section-hero section-hero--pattern">
      <div className="hero">
        <div className="hero__text-box">
          <p className="subheading margin-top-small">Water For All!</p>
          <h1 className="heading-secondary heading-secondary--dark">
            Sanda Ruwan Gida
          </h1>
          <span className="margin-top-small inline">
            <img
              src={require('../images/ruwa.png')}
              alt=""
              className="inline inline__img"
            />{' '}
          </span>
          <h1 className="header__description  header__description--left">
            Sanda Ruwan Gida is a water distribution company based in Kano.
            Specialized in supplying filtered and clean water to homes,
            hospitals, factories and offices. The use of unsafe water is
            responsible for 1.2 million deaths each year (Our World in Data,
            2019). Unsafe water is one of the world’s largest health and
            environmental problems – particularly for the poorest in the world.
            Lack of access to safe water sources is a leading risk factor for
            infectious diseases, including cholera, diarrhea, dysentery,
            hepatitis A, typhoid and polio. It also exacerbates malnutrition,
            and in particular, childhood.{' '}
            <div class="gallery">
              <figure class="gallery-item">
                <img
                  src={require('../images/ruwn/1.jpg')}
                  alt="of beautifully
            arranged food"
                />
                {/* <!-- <figcaption>Caption</figcaption> --> */}
              </figure>
              <figure class="gallery-item">
                <img
                  src={require('../images/ruwn/2.jpg')}
                  alt=" of beautifullyarranged food"
                />
              </figure>
              <figure class="gallery-item">
                <img
                  src={require('../images/ruwn/3.jpg')}
                  alt=" of beautifully
            arranged food"
                />
              </figure>
            </div>{' '}
            Sanda Ruwan Gida uses mobile tanks ranging from 1,000 liters to
            30,000 liters to distribute water to parts of Kano with severe water
            scarcity. The cost of water is subsidized to make it affordable to
            the poorest in the fringes of the urban areas in Kano State. In some
            neighbourhoods, Sanda has also created Sub depots where consumers
            and come to buy small quantities.
          </h1>
          <div class="gallery">
            <figure class="gallery-item">
              <img
                src={require('../images/ruwn/4.jpg')}
                alt="of beautifully
            arranged food"
              />
              {/* <!-- <figcaption>Caption</figcaption> --> */}
            </figure>
            <figure class="gallery-item">
              <img
                src={require('../images/ruwn/5.jpg')}
                alt=" of beautifullyarranged food"
              />
            </figure>
            <figure class="gallery-item">
              <img
                src={require('../images/ruwn/6.jpg')}
                alt=" of beautifully
            arranged food"
              />
            </figure>
          </div>

          <div className="hero">
            <div className="hero__text-box">
              <div
                style={{
                  // fontSize: 25,
                  textAlign: 'center',
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Vision
                </h1>
                <h1
                  className="header__description  header__description--left margin-top-small"
                  style={{
                    // fontSize: 25,
                    textAlign: 'center',
                  }}
                >
                  To provide clean and accessible water to all.
                </h1>
              </div>
              <div
                style={{
                  textAlign: 'center',

                  marginTop: 100,
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Mission
                </h1>
                <h1
                  className="header__description  header__description--left margin-top-small"
                  style={{
                    // fontSize: 25,
                    textAlign: 'center',
                  }}
                >
                  To become the leading provider of clean and accessible water
                  in Northern Nigeria.
                </h1>
              </div>
            </div>
          </div>
          {/* <div className="heading-tertiary margin-top">
            Sustainable Development Goals (SDGs)
            <div className="header__grid header__grid--three">
              <img
                className="header__grid--imgs"
                src={require("../images/app/nopoverty.png")}
                alt=""
              />
              <img
                className="header__grid--imgs"
                src={require("../images/app/zerohunger.png")}
                alt=""
              />{" "}
              <img
                className="header__grid--imgs"
                src={require("../images/app/decentwork.png")}
                alt=""
              />
            </div>
          </div>
          <div className="heading-tertiary margin-top">
            African Union Development Agency-NEPAD (AUDA-NEPAD) Aspirations
            <img
              className="header__grid--imgs--big"
              src={require("../images/app/aspirations.png")}
              alt=""
            />{" "}
          </div>
          <h1 className="heading-tertiary--small">
            Kirana is a Sanda Tireda initiative that eases access to credit
            facilities for small scale shop owners.
          </h1>
          <div className="header__grid header__grid--two">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/2B5-DKnzlT0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/6cXKQ3ng_cc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>{" "}
          <div className="margin-top-small center">
            <p className="subheading  margin-top center">
              Increased Earning Capacity for Micro-Entrepreneurs
            </p>
            <h1 className="heading-secondary heading-secondary--dark">
              The Sanda Difference
            </h1>
            <div className="header__grid header__grid--three header__grid--threes left">
              <div className="header__difference">
                <div className="header__new-icon">
                  <FiTruck />
                </div>
                <h1 className="header-description">Convenience</h1>
                <p className="header__feature-text">
                  We eliminate the need to travel to the market by delivering
                  inventory to their shops free of charge.
                </p>
              </div>{" "}
              <div className="header__difference">
                <div className="header__new-icon">
                  <GiReceiveMoney />
                </div>
                <h1 className="header-description">Microcredit</h1>
                <p className="header__feature-text">
                  We offer credit of up to 200,000 naira in inventory to each
                  Kirana Store on a 30-day cycle.
                </p>
              </div>
              <div className="header__difference">
                <div className="header__new-icon">
                  {" "}
                  <IoIosPhonePortrait />
                </div>
                <h1 className="header-description">Ease of Use</h1>
                <p className="header__feature-text">
                  We have a user-friendly mobile phone application where all
                  orders from franchise stores are made.
                </p>
              </div>
            </div>
          </div>
          <div className="margin-top-small center">
            <p className="subheading  margin-top center">
              Increased Earning Capacity for Micro-Entrepreneurs
            </p>
            <h1 className="heading-secondary heading-secondary--dark">
              The Kirana App
            </h1>
            <div className="header__grid header__grid--seven">
              <div className=" header__description  header__description--left header__span">
                Sanda Tireda leverages technology to monitor in real time its
                operations stock in warehouses and Kirana Stores. Furthermore,
                Sanda Tireda uses a mobile phone application to manage its sales
                and inventories across the Kirana stores. All orders from
                franchise stores are made via the app and goods are delivered to
                the Kirana’s using Sanda’s dedicated fleet of over 30 vehicles.
                <a href="https://play.google.com/store/apps/details?id=com.sunda.tireda&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    className="margin-top-small header__grid--imgs-small"
                  />
                </a>
              </div>
              <div>
                <img
                  src={require("../images/app/app-mockup-1.png")}
                  className="header__grid--imgs-small"
                  alt=""
                />
              </div>
            </div>
          </div> */}
          <div className="form-center">
            <img
              src={require('../images/ruwn/7.png')}
              alt=""
              className="header__grid--imgs"
            />
          </div>
          <div className="margin-top-small center">
            <p className="subheading  margin-top center">
              We deliver results that satisfy
            </p>
            <h1 className="heading-secondary heading-secondary--dark">
              Testimonials
            </h1>

            <div className="header__description header__description--left header__description--right">
              Before Sanda Ruwangida, I used to walk for up to 20 minutes per
              trip to fill up a total of 5 carts per day. Now I can fill up to
              20 carts because I only need to walk for 5 minutes per trip. —
              Yunusa
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
