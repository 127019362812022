import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';

const Card = ({ item }) => {
  return (
    <li className="card">
      {/* <span class="material-icons">{props.icon}</span> */}
      <img src={item.item_image} alt="" className="card__images" />
    </li>
  );
};

const CarouselNew = () => {
  const [moveClass, setMoveClass] = useState('');
  const [data, setData] = useState([]);
  const [display, setDisplay] = useState(false);

  const getData = useCallback(() => {
    fetch(
      `https://galaxybis.ebudgetkano.ng/sanda-server/account/get/catalogue?query_type=by_cat&category=${
        !display ? 'FOO' : 'NON'
      }`,
    )
      .then((raw) => raw.json())
      .then((data) => setData(data.results))
      .catch((err) => console.log(err));
  }, [display]);
  useEffect(() => {
    document.documentElement.style.setProperty('--num', data.length);
    getData();
  }, [data.length, getData]);

  const shiftPrev = (copy) => {
    let lastcard = copy.pop();
    copy.splice(0, 0, lastcard);
    setData(copy);
  };

  const shiftNext = (copy) => {
    let firstcard = copy.shift();
    copy.splice(copy.length, 0, firstcard);
    setData(copy);
  };

  const handleAnimationEnd = () => {
    if (moveClass === 'prev') {
      shiftNext([...data]);
    } else if (moveClass === 'next') {
      shiftPrev([...data]);
    }
    setMoveClass('');
  };

  return (
    <>
      <div className="margin-top"></div>
      <div className="food">
        <div>
          <button
            className={
              !display ? 'food__submit' : 'food__submit food__submit--inert'
            }
            onClick={() => setDisplay(false)}
          >
            Food Item
          </button>
          <button
            className={
              display ? 'food__submit' : 'food__submit food__submit--inert'
            }
            onClick={() => setDisplay(true)}
          >
            Non Food Item
          </button>
        </div>
      </div>
      <div className="carouselwrapper module-wrapper">
        <div className="ui">
          <button onClick={() => setMoveClass('next')} className="prev">
            <span className="material-icons">chevron_left</span>
          </button>
          <button onClick={() => setMoveClass('prev')} className="next">
            <span className="material-icons">chevron_right</span>
          </button>
        </div>
        <ul
          onAnimationEnd={handleAnimationEnd}
          className={`${moveClass} carousel`}
        >
          {/* {JSON.stringify(data)} */}
          {data.map((t, index) => (
            <Card key={t.copy + index} item={t} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default CarouselNew;
