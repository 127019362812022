import React from "react";
import Aboutus from "./Style";

import Hero from "./Hero";

export default function Home() {
  return (
    <>
      <div className="header__background">
        <Aboutus />
      </div>
      <Hero />
    </>
  );
}
