import React from 'react'
import { FiTruck } from 'react-icons/fi'
import { IoIosPhonePortrait } from 'react-icons/io'
import { GiReceiveMoney } from 'react-icons/gi'
export default function SandaDireba() {
  return (
    <section className="section-hero section-hero--pattern">
      <div className="hero">
        <div className="hero__text-box">
          <p className="subheading margin-top-small">
            Providing Reliable And Cost Effective Transportation
          </p>
          <h1 className="heading-secondary heading-secondary--dark">
            Sanda Direba
          </h1>
          <span className="margin-top-small inline">
            <img
              src={require('../images/ddd.jpg')}
              alt=""
              className="inline inline__img"
            />{' '}
          </span>
          <h1 className="header__description margin-top-small header__description--left">
            Sanda Direba provides transportation services for commuters within
            the Kano metropolis. Our goal is to establish a service that is
            safe, reliable, affordable, and comfortable for all customers.
            <br></br> Our fleet consists of two major types of vehicles.
            <br></br>
            <br></br> <span className="orange">●</span> Uwar Gida: The purple
            Minibus is exclusively designed for female passengers in Kano
            metropolis. <br></br> <span className="orange">●</span> An Dai Dai
            Ta: The yellow & purple Passenger Truck accommodates both gender of
            commuters within the Kano metropolis.
          </h1>
          <div class="gallery">
            <figure class="gallery-item">
              <img
                src={require('../images/direba/1.jpg')}
                alt="of beautifully
            arranged food"
              />
              {/* <!-- <figcaption>Caption</figcaption> --> */}
            </figure>
            <figure class="gallery-item">
              <img
                src={require('../images/direba/2.jpg')}
                alt=" of beautifullyarranged food"
              />
            </figure>
            <figure class="gallery-item">
              <img
                src={require('../images/direba/3.jpg')}
                alt=" of beautifully
            arranged food"
              />
            </figure>
          </div>

          <div className="hero">
            <div className="hero__text-box">
              <div
                style={{
                  // fontSize: 25,
                  textAlign: 'center',
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Vision
                </h1>
                <h1
                  className="header__description  header__description--left margin-top-small"
                  style={{
                    // fontSize: 25,
                    textAlign: 'center',
                  }}
                >
                  To reinvent transportation in Northern Nigeria to achieve
                  sustainable impact for commuters.
                </h1>
              </div>
              <div
                style={{
                  textAlign: 'center',

                  marginTop: 100,
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Mission
                </h1>
                <h1
                  className="header__description  header__description--left margin-top-small"
                  style={{
                    // fontSize: 25,
                    textAlign: 'center',
                  }}
                >
                  To sustainably transform transportation by providing safe,
                  reliable, comfortable and cost-effective service to commuters
                  across Northern Nigeria.
                </h1>
              </div>
            </div>
          </div>
          {/* <div className="heading-tertiary margin-top">
            Sustainable Development Goals (SDGs)
            <div className="header__grid header__grid--three">
              <img
                className="header__grid--imgs"
                src={require("../images/app/nopoverty.png")}
                alt=""
              />
              <img
                className="header__grid--imgs"
                src={require("../images/app/zerohunger.png")}
                alt=""
              />{" "}
              <img
                className="header__grid--imgs"
                src={require("../images/app/decentwork.png")}
                alt=""
              />
            </div>
          </div>
          <div className="heading-tertiary margin-top">
            African Union Development Agency-NEPAD (AUDA-NEPAD) Aspirations
            <img
              className="header__grid--imgs--big"
              src={require("../images/app/aspirations.png")}
              alt=""
            />{" "}
          </div>
         
          <div className="header__grid header__grid--two">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/2B5-DKnzlT0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/6cXKQ3ng_cc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>{" "} */}
          <div className=" center">
            <p className="subheading  margin-top-small center">
              Increased Earning Capacity for Micro-Entrepreneurs
            </p>
            <h1 className="heading-secondary heading-secondary--dark">
              The Sanda Direba Difference
            </h1>
            <div className="header__grid header__grid--three header__grid--threes left">
              <div className="header__difference">
                <div className="header__new-icon">
                  <FiTruck />
                </div>
                <h1 className="header-description">Safety</h1>
                <p className="header__feature-text">
                  Great caution is taken to ensure our customers’ safety and
                  satisfaction.
                </p>
              </div>{' '}
              <div className="header__difference">
                <div className="header__new-icon">
                  <GiReceiveMoney />
                </div>
                <h1 className="header-description">Honesty & Commitment</h1>
                <p className="header__feature-text">
                  Our drivers are honest when conducting ourselves in our
                  operation.
                </p>
              </div>
              <div className="header__difference">
                <div className="header__new-icon">
                  {' '}
                  <IoIosPhonePortrait />
                </div>
                <h1 className="header-description">Employment</h1>
                <p className="header__feature-text">
                  Sanda Direba has a fleet of 16 drivers. It is believed that
                  mobility is a key driver of development, with positive
                  economic and social benefits from investment in transport.
                </p>
              </div>
            </div>
          </div>
          <h1 className="heading-tertiary--smalls">Sanda Direba Routes</h1>
          <div className="header__description--left margin-top-small heading-tertiary--smalls--er flex">
            {' '}
            <div>
              {' '}
              <span className="orange">●</span> Hotoro – Bata
            </div>{' '}
            <div>
              {' '}
              <span className="orange">●</span> Hotoro – IBB way{' '}
            </div>
            <div>
              <span className="orange">●</span>Zaria Road{' '}
            </div>
            <div>
              <span className="orange">●</span>
              Katsina Road
            </div>
            <div>
              <span className="orange">●</span> Sharada
            </div>
            <div>
              <span className="orange">●</span> Hadeja Road{' '}
            </div>
            <div>
              <span className="orange">●</span> Kwana Hudu
            </div>
          </div>
          {/* <div className="margin-top-small center">
            <p className="subheading  margin-top center">
              Increased Earning Capacity for Micro-Entrepreneurs
            </p>
            <h1 className="heading-secondary heading-secondary--dark">
              The Kirana App
            </h1>
            <div className="header__grid header__grid--seven">
              <div className=" header__description  header__description--left header__span">
                Sanda Tireda leverages technology to monitor in real time its
                operations stock in warehouses and Kirana Stores. Furthermore,
                Sanda Tireda uses a mobile phone application to manage its sales
                and inventories across the Kirana stores. All orders from
                franchise stores are made via the app and goods are delivered to
                the Kirana’s using Sanda’s dedicated fleet of over 30 vehicles.
                <a href="https://play.google.com/store/apps/details?id=com.sunda.tireda&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    className="margin-top-small header__grid--imgs-small"
                  />
                </a>
              </div>
              <div>
                <img
                  src={require("../images/app/app-mockup-1.png")}
                  className="header__grid--imgs-small"
                  alt=""
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}
