import React from 'react'

export default function UwarGida() {
  return (
    <section className="section-hero section-hero--pattern">
      <div className="hero">
        <div className="hero__text-box">
          <p className="subheading margin-top-small">
            empowering women-owned enterprises
          </p>
          <h1 className="heading-secondary heading-secondary--dark">
            Sanda Uwar Gida
          </h1>
          <span className="margin-top-small inline">
            <img
              src={require('../images/uwargida.png')}
              alt=""
              className="inline inline__img"
            />{' '}
          </span>
          <h1 className="header__description  header__description--left margin-top-small">
            Uwar Gida is a business unit within Sanda Kirana with a focus on
            empowering women owned enterprise. Aptly named “Uwar Gida” meaning
            mother of the house. Women are provided the opportunity to expand
            their small businesses from the comfort of their homes. <br></br>
            Uwar Gida Project was created to support women who are already in
            the business but have insufficient capital to grow. Uwar Gida adopts
            a cooperative system which minimizes the credit risk due to group
            trusts They can also access the benefits of the Kirana if they meet
            the terms and conditions.
          </h1>
          <div className="hero">
            <div className="hero__text-box">
              <div
                style={{
                  // fontSize: 25,
                  textAlign: 'center',
                
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Vision
                </h1>
                <h1 className="header__description  header__description--left margin-top-small"   style={{
                  // fontSize: 25,
                  textAlign: 'center',
                
                }}>
                  To build the economic resilience of women all over Northern
                  Nigeria.
                </h1>
              </div>
              <div
                style={{
                  textAlign: 'center',
                
                  marginTop: 100,
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Mission
                </h1>
                <h1 className="header__description  header__description--left margin-top-small"   style={{
                  // fontSize: 25,
                  textAlign: 'center',
                
                }}>
                  To empower women-owned enterprises and to enhance capacity
                  development through the use of technology.
                </h1>
              </div>
            </div>
          </div>
          {/* <div className="heading-tertiary margin-top">
            Sustainable Development Goals (SDGs)
            <div className="header__grid header__grid--three">
              <img
                className="header__grid--imgs"
                src={require("../images/app/nopoverty.png")}
                alt=""
              />
              <img
                className="header__grid--imgs"
                src={require("../images/app/zerohunger.png")}
                alt=""
              />{" "}
              <img
                className="header__grid--imgs"
                src={require("../images/app/decentwork.png")}
                alt=""
              />
            </div>
          </div>
          <div className="heading-tertiary margin-top">
            African Union Development Agency-NEPAD (AUDA-NEPAD) Aspirations
            <img
              className="header__grid--imgs--big"
              src={require("../images/app/aspirations.png")}
              alt=""
            />{" "}
          </div> */}
          {/* <h1 className="heading-tertiary--small">
            Kirana is a Sanda Tireda initiative that eases access to credit
            facilities for small scale shop owners.
          </h1> */}{' '}
          {/* <div className="margin-top-small center">
            <p className="subheading  margin-top center">
              Increased Earning Capacity for Micro-Entrepreneurs
            </p>
            <h1 className="heading-secondary heading-secondary--dark">
              The Sanda Difference
            </h1>
            <div className="header__grid header__grid--three header__grid--threes left">
              <div className="header__difference">
                <div className="header__new-icon">
                  <FiTruck />
                </div>
                <h1 className="header-description">Convenience</h1>
                <p className="header__feature-text">
                  We eliminate the need to travel to the market by delivering
                  inventory to their shops free of charge.
                </p>
              </div>{" "}
              <div className="header__difference">
                <div className="header__new-icon">
                  <GiReceiveMoney />
                </div>
                <h1 className="header-description">Microcredit</h1>
                <p className="header__feature-text">
                  We offer credit of up to 200,000 naira in inventory to each
                  Kirana Store on a 30-day cycle.
                </p>
              </div>
              <div className="header__difference">
                <div className="header__new-icon">
                  {" "}
                  <IoIosPhonePortrait />
                </div>
                <h1 className="header-description">Ease of Use</h1>
                <p className="header__feature-text">
                  We have a user-friendly mobile phone application where all
                  orders from franchise stores are made.
                </p>
              </div>
            </div>
          </div> */}
          <div className="margin-top-small center">
            <p className="subheading  margin-top center">
              We deliver results that satisfy
            </p>
            <h1 className="heading-secondary heading-secondary--dark">
              Testimonials
            </h1>

            <div className="header__description header__description--left header__description--right margin-top">
              To empower women-owned enterprises and to enhance capacity
              development through the use of technology. — Hajara
            </div>
          </div>
          <div className="header__grid header__grid--two">
            <iframe
              width=""
              height="315"
              src="https://www.youtube.com/embed/ihKnMeEO53c"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            {/* <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/6cXKQ3ng_cc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe> */}
          </div>
        </div>
      </div>
    </section>
  )
}
