import React from "react";
import FooterBond from "./FooterBond";

export default function Footer() {
  return (
    <>
      <FooterBond />
    </>
  );
}
