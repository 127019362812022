import React, { useEffect, useRef, useState } from 'react'
import { FiArrowRight, FiChevronDown } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router'
// import Banner from "./Banner";
import logo from '../images/app/sandatireda.png'

export default function Header() {
  const navigate = useNavigate()
  const pathLocation = useLocation()
  const homePath = pathLocation.pathname === '/'
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 })
  const headerRef = useRef(null)

  // handle scroll event
  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: elHeight })
    } else {
      setSticky({ isSticky: false, offset: 0 })
    }
  }

  // add/remove scroll event listener
  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect()
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height)
    }

    window.addEventListener('scroll', handleScrollEvent)

    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
    }
  }, [])

  const [isNavExpanded, setIsNavExpanded] = useState(false)
  return (
    <header
      className={
        homePath
          ? `header${sticky.isSticky ? ' sticky' : ''}`
          : 'header header--not-home'
      }
      ref={headerRef}
    >
      <div className="header__alignment">
        <div className="header__container">
          <div className="header__logo">
            <img
              src={logo}
              alt=""
              className="header__logo-alignment"
              onClick={() => {
                navigate('/')
                setIsNavExpanded(false)
              }}
            />
          </div>
          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded)
            }}
          >
            {/* icon from heroicons.com */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={
              isNavExpanded
                ? 'header__nav-wrapper header__nav-wrapper--expanded'
                : 'header__nav-wrapper'
            }
          >
            <nav className="header__nav-list">
              <div
                className={
                  pathLocation.pathname === '/about-us'
                    ? 'header__nav-items header__nav-items--active'
                    : 'header__nav-items'
                }
              >
                <button
                  className=" edits header__links"
                  onClick={() => {
                    navigate('/about-us')
                    setIsNavExpanded(false)
                  }}
                >
                  About Us
                </button>
              </div>
              {/* <div
                className={
                  pathLocation.pathname === "/ourpartners"
                    ? "header__nav-items header__nav-items--active"
                    : "header__nav-items"
                }
              >
                <button
                  className="edits header__links"
                  onClick={() => navigate("/ourpartners")}
                >
                  Our Partners
                </button>
              </div> */}
              <div
                className={
                  pathLocation.pathname === '/kirana' ||
                  pathLocation.pathname === '/uwargida' ||
                  pathLocation.pathname === '/sandadireba' ||
                  pathLocation.pathname === '/sandaruwangida'
                    ? 'header__nav-items header__nav-items--active'
                    : 'header__nav-items'
                }
              >
                <button className="admin__props edits header__links">
                  Business Units
                  <FiChevronDown />
                  <div className="admin__user-dropdown">
                    {/* <div className="admin__dropdown-divider"></div> */}
                    <div
                      className={
                        pathLocation.pathname === '/kirana'
                          ? 'admin__user-dropdown-title admin__user-dropdown-title--active'
                          : 'admin__user-dropdown-title'
                      }
                      onClick={() => {
                        navigate('/kirana')
                        setIsNavExpanded(false)
                      }}
                    >
                      <div className="admin__user-dropdown-names">
                        Sanda Kirana
                      </div>
                    </div>
                    {/* <div className="admin__dropdown-divider"></div> */}
                    <div
                      className={
                        pathLocation.pathname === '/uwargida'
                          ? 'admin__user-dropdown-title admin__user-dropdown-title--active'
                          : 'admin__user-dropdown-title'
                      }
                      onClick={() => {
                        navigate('/uwargida')
                        setIsNavExpanded(false)
                      }}
                    >
                      <div className="admin__user-dropdown-names">
                        Sanda Uwargida
                      </div>
                    </div>
                    <div
                      className={
                        pathLocation.pathname === '/sandadireba'
                          ? 'admin__user-dropdown-title admin__user-dropdown-title--active'
                          : 'admin__user-dropdown-title'
                      }
                      onClick={() => {
                        navigate('/sandadireba')
                        setIsNavExpanded(false)
                      }}
                    >
                      <div className="admin__user-dropdown-names">
                        Sanda Direba
                      </div>
                    </div>
                    <div
                      className={
                        pathLocation.pathname === '/sandaruwangida'
                          ? 'admin__user-dropdown-title admin__user-dropdown-title--active'
                          : 'admin__user-dropdown-title'
                      }
                      onClick={() => {
                        navigate('/sandaruwangida')

                        setIsNavExpanded(false)
                      }}
                    >
                      <div className="admin__user-dropdown-names">
                        Sanda Ruwangida
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div
                className={
                  pathLocation.pathname === '/csr'
                    ? 'header__nav-items header__nav-items--active'
                    : 'header__nav-items'
                }
              >
                <button
                  className="edits header__links"
                  onClick={() => {
                    navigate('/csr')
                    setIsNavExpanded(false)
                  }}
                >
                  CSR
                </button>
              </div>
              <div
                className={
                  pathLocation.pathname === '/competition'
                    ? 'header__nav-items header__nav-items--active'
                    : 'header__nav-items'
                }
              >
                <button
                  className=" edits header__links"
                  onClick={() => {
                    navigate('/competition')
                    setIsNavExpanded(false)
                  }}
                >
                  Competition
                </button>
              </div>
              {/* <div
                className={
                  pathLocation.pathname === "/news-blog" ||
                  pathLocation.pathname === "/gallery"
                    ? "header__nav-items header__nav-items--active"
                    : "header__nav-items"
                }
              >
                <button className="admin__props edits header__links">
                  Media <FiChevronDown />
                  <div className="admin__user-dropdown">
                    <div
                      className={
                        pathLocation.pathname === "/news-blog"
                          ? "admin__user-dropdown-title admin__user-dropdown-title--active"
                          : "admin__user-dropdown-title"
                      }
                      onClick={() => {
                        navigate("/news-blog");
                        setIsNavExpanded(false);
                      }}
                    >
                      <div className="admin__user-dropdown-names">
                        News/Blog
                      </div>
                    </div>
                    <div
                      className={
                        pathLocation.pathname === "/gallery"
                          ? "admin__user-dropdown-title admin__user-dropdown-title--active"
                          : "admin__user-dropdown-title"
                      }
                      onClick={() => {
                        navigate("/gallery");
                        setIsNavExpanded(false);
                      }}
                    >
                      <div className="admin__user-dropdown-names">Gallery</div>
                    </div>
                  </div>
                </button>
              </div> */}
              {/* <div
                className={
                  pathLocation.pathname === "/kiranamap"
                    ? "header__nav-items header__nav-items--active"
                    : "header__nav-items"
                }
              >
                <button
                  className="edits header__links"
                  onClick={() => navigate("/kiranamap")}
                >
                  Kirana Map
                </button>
              </div> */}
              <div
                className={
                  pathLocation.pathname === '/contactus'
                    ? 'header__nav-items header__nav-items--active'
                    : 'header__nav-items'
                }
              >
                <button
                  className="edits header__links"
                  onClick={() => {
                    navigate('/contactus')
                    setIsNavExpanded(false)
                  }}
                >
                  Contact
                </button>
              </div>
              <div className="header__button ">
                <button className="btn" onClick={() => setIsNavExpanded(false)}>
                  <div className="btn__text">Register</div>
                  <FiArrowRight />
                </button>
              </div>{' '}
            </nav>
          </div>
          <div className="header__button header__button--outer">
            <button className="btn">
              <div className="btn__text">Register</div>
              <FiArrowRight />
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}
