import React from "react";
import ContactForm from "./ContactForm";
// import { FiArrowRight } from "react-icons/fi";
export default function ContactUs() {
  return (
    <section className="section-hero section-hero--pattern">
      <div className="hero ">
        <ContactForm />
      </div>
    </section>
  );
}
