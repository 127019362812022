import "./App.scss";
import AppNavigation from "./AppNavigation";
import ScrollToTop from "./component/ScrollToTop";
function App() {
  return (
    <div>
      <ScrollToTop />
      <AppNavigation />
    </div>
  );
}

export default App;
