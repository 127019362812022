import React from "react";
import { partners } from "./partners";

export default function OurPartner() {
  return (
    <div className="hero__text-box margin-top">
      <p className="subheading">One team – One mission.</p>
      <h1 className="heading-secondary heading-secondary--dark">
        Our Partners
      </h1>
      <h1 className="header__description header__grid">
        {partners.map((i, idx) => (
          <img src={i.img} key={idx} alt="" className="header__logo--new" />
        ))}
      </h1>
    </div>
  );
}
