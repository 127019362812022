import React from 'react'
import { FiTruck } from 'react-icons/fi'
import { IoIosPhonePortrait } from 'react-icons/io'
import { GiReceiveMoney } from 'react-icons/gi'

export default function Kirana() {
  return (
    <section className="section-hero section-hero--pattern">
      <div className="hero">
        <div className="hero__text-box">
          <p className="subheading margin-top-small">
            Empowering Micro, Small And Medium Enterprises
          </p>
          <h1 className="heading-secondary heading-secondary--dark">
            Sanda Kirana
          </h1>
          <h1 className="header__description  header__description--left margin-top-small">
            Sanda Tireda established the Kirana initiative in July 2021 to
            enable small store owners to expand their business, while allowing
            manufacturers to have direct access to retailers. Sanda Tireda
            leverages technology through a customized software application which
            enables the Kirana customers from orders to delivery and ultimate
            sales to consumers. Through this application, the retailers can also
            pay for the goods ordered.<br></br>
            <b> Kirana</b> is inspired by the Indian word used to describe “a
            small, usually family owned, shop selling groceries and other
            household sundries”. Under the Sanda Kirana model, Sanda Tireda
            offers Kirana stores a 30-day interest free credit with free door to
            door delivery. This service is presently available within Kano state
            and Jigawa state. The importance of microfinance in poverty
            reduction has created a compelling need to design strategies for
            providing financial services to the vulnerable, poor and low-income
            groups on a sustainable basis.
          </h1>
          <div className="hero">
            <div className="hero__text-box">
              <div
                style={{
                  // fontSize: 25,
                  textAlign: 'center',
                  marginLeft: 100,
                  marginRight: 100,
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Vision
                </h1>
                <h1 className="header__description  header__description--left margin-top-small">
                  To create a business environment where micro, small and medium
                  enterprises will thrive across Nigeria.
                </h1>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: 100,
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Mission
                </h1>
                <h1
                  className="header__description  header__description--left margin-top-small"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  To empower micro, small and medium enterprises through the use
                  of technology.
                </h1>
              </div>
            </div>
          </div>
          <div className="margin-top-small center">
            <p className="subheading  margin-top center">
              Increased Earning Capacity for Micro-Entrepreneurs
            </p>
            <h1 className="heading-secondary heading-secondary--dark">
              The Sanda Difference
            </h1>
            <div className="header__grid header__grid--three header__grid--threes left">
              <div className="header__difference">
                <div className="header__icon-coat">
                  <div className="header__new-icon">
                    <FiTruck />
                  </div>
                </div>
                <h1 className="header-description">Convenience</h1>
                <p className="header__feature-text">
                  We eliminate the need to travel to the market by delivering
                  inventory to their shops free of charge.
                </p>
              </div>
              <div className="header__difference">
                {' '}
                <div className="header__icon-coat">
                  <div className="header__new-icon">
                    <GiReceiveMoney />
                  </div>
                </div>
                <h1 className="header-description">Microcredit</h1>
                <p className="header__feature-text">
                  We offer credit of up to 200,000 naira in inventory to each
                  Kirana Store on a 30-day cycle.
                </p>
              </div>
              <div className="header__difference">
                {' '}
                <div className="header__icon-coat">
                  <div className="header__new-icon">
                    {' '}
                    <IoIosPhonePortrait />
                  </div>
                </div>
                <h1 className="header-description">Ease of Use</h1>
                <p className="header__feature-text">
                  We have a user-friendly mobile phone application where all
                  orders from franchise stores are made.
                </p>
              </div>
            </div>
          </div>{' '}
          <div className="margin-top-small center">
            <p className="subheading  margin-top center">
              Increased Earning Capacity for Micro-Entrepreneurs
            </p>
            <h1 className="heading-secondary heading-secondary--dark">
              The Kirana App
            </h1>
            <div className="header__grid--seven">
              <div className=" header__description  header__description--left header__span">
                Sanda Tireda leverages technology to monitor in real time its
                operations stock in warehouses and Kirana Stores. Furthermore,
                Sanda Tireda uses a mobile phone application to manage its sales
                and inventories across the Kirana stores. All orders from
                franchise stores are made via the app and goods are delivered to
                the Kirana’s using Sanda’s dedicated fleet of over 30 vehicles.
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.sunda.tireda&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      className="margin-top-small header__grid--imgs-small"
                    />
                  </a>
                </div>
              </div>
              <div className="header__span--smaller">
                <img
                  src={require('../images/app-mockup.png')}
                  className="header__grid--imgs-small"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="heading-tertiary margin-top">
            Sustainable Development Goals (SDGs)
            <div className="header__flex">
              <img
                className="header__grid--imgs"
                src={require('../images/app/nopoverty.png')}
                alt=""
              />
              <img
                className="header__grid--imgs"
                src={require('../images/app/zerohunger.png')}
                alt=""
              />{' '}
              <img
                className="header__grid--imgs"
                src={require('../images/app/decentwork.png')}
                alt=""
              />
            </div>
          </div>
          <div className="heading-tertiary margin-top">
            African Union Development Agency-NEPAD (AUDA-NEPAD) Aspirations
            <img
              className="header__grid--imgs--big"
              src={require('../images/app/aspirations.png')}
              alt=""
            />{' '}
          </div>
          <h1 className="heading-tertiary--small">
            Kirana is a Sanda Tireda initiative that eases access to credit
            facilities for small scale shop owners.
          </h1>
          <div className="header__youtube margin-top">
            <iframe
              width=""
              height="315"
              src="https://www.youtube.com/embed/2B5-DKnzlT0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              width=""
              height="315"
              src="https://www.youtube.com/embed/6cXKQ3ng_cc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>{' '}
        </div>
      </div>
    </section>
  )
}
