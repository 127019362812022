import React from 'react'
import { useNavigate } from 'react-router'

export default function FooterBond() {
  const navigate = useNavigate()
  // const pathLocation = useLocation();

  return (
    <section class="footer--color">
      <section class="footer">
        <div class="footer--logo-column">
          <img
            src={require('../images/app/sandatireda-white.png')}
            class="footer--logo"
            alt="footer Logo"
          />
          <p class="footer--text">
            Creating an environment which motivates and empowers sole traders,
            SME’s and businesses to thrive by providing direct access to
            household consumer goods and services.
          </p>
        </div>
        <div class="footer--navigation-column">
          <div class="footer--title">Company</div>
          <div>
            <ul class="footer--nav__links">
              <li
                class="footer--nav__item"
                onClick={() => {
                  navigate('/about-us')
                }}
              >
                <span class="footer--nav__link footer--text">About Us</span>
              </li>
              <li
                class="footer--nav__item"
                onClick={() => {
                  navigate('/csr')
                }}
              >
                <span class="footer--nav__link footer--text">CSR</span>
              </li>
              <li
                class="footer--nav__item"
                onClick={() => {
                  navigate('/gallery')
                }}
              >
                <span class="footer--nav__link footer--text">Media</span>
              </li>
              <li
                class="footer--nav__item"
                b
                onClick={() => {
                  navigate('/contactus')
                }}
              >
                <span class="footer--nav__link footer--text">Contact</span>
              </li>
            </ul>
          </div>
        </div>{' '}
        <div class="footer--navigation-column">
          <div class="footer--title">Business Units</div>
          <div>
            <ul class="footer--nav__links">
              <li
                class="footer--nav__item"
                onClick={() => {
                  navigate('/kirana')
                }}
              >
                <span class="footer--nav__link footer--text">Kirana</span>
              </li>
              <li
                class="footer--nav__item"
                onClick={() => {
                  navigate('/uwargida')
                }}
              >
                <span class="footer--nav__link footer--text">Uwargida</span>
              </li>
              <li
                class="footer--nav__item"
                onClick={() => {
                  navigate('/sandadireba')
                }}
              >
                <span class="footer--nav__link footer--text">Direba</span>
              </li>
              <li
                class="footer--nav__item"
                onClick={() => {
                  navigate('/sandaruwangida')
                }}
              >
                <span class="footer--nav__link footer--text">Ruwangida</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer--contact-column">
          <div class="footer--title">Contact</div>
          <div class="footer--contact-details">
            <div class="footer--contacts">
              <img
                src={require('../images/email.svg').default}
                alt="email icon"
                class="footer--icon"
              />
              <p class="footer--text">info@sandatireda.com</p>
            </div>
            <div class="footer--contacts">
              <img
                src={require('../images/phone.svg').default}
                alt="phone icon"
                class="footer--icon"
              />
              <p class="footer--text">+234(0) 809 7766 556</p>
            </div>
          </div>
        </div>
        <div class="footer--social-column">
          <div class="footer--title">Socials</div>
          <div class="footer--socials">
            <a
              className="links"
              href="https://www.linkedin.com/company/sanda-tireda/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require('../images/linkedin.svg').default}
                alt="linkedin icon"
                class="footer--social-icon"
                onClick={() => navigate('/')}
              />
            </a>
            <a
              className="links"
              href="https://www.instagram.com/sandatireda/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require('../images/instagram.svg').default}
                alt="instagram icon"
                class="footer--social-icon"
              />
            </a>
            <a
              className="links"
              href="https://twitter.com/sandatireda"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require('../images/twitter.svg').default}
                alt="twitter icon"
                class="footer--social-icon"
                onClick={() => navigate('/')}
              />
            </a>
            <a
              className="links"
              href="https://web.facebook.com/sandatireda"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require('../images/facebook.svg').default}
                alt="facebook icon"
                class="footer--social-icon"
                onClick={() => navigate('/')}
              />
            </a>
          </div>
        </div>
      </section>
      <aside class="footer--foot-note">
        <a href="https://brainstorm.ng" target="_blank" rel="noreferrer">
          <div class="footer--copyright">
            © 2022 Designed and {'</>'} by Brainstorm IT Solutions, Kano. All
            rights reserved.
          </div>
        </a>
        <div class="footer--privacy-policies">
          <p
            class="footer--privacy"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/privacy-policy')
            }}
          >
            Privacy Policy
          </p>
          <p class="footer--faqs">FAQS</p>
        </div>
      </aside>
    </section>
  )
}
