import React from 'react'

export default function CSR() {
  return (
    <section className="section-hero section-hero--pattern">
      <div className="hero">
        <div className="hero__text-box hero__text-box--container">
          <p className="subheading">Development Service</p>
          <h1 className="heading-secondary heading-secondary--dark">
            Corporate Social Responsibility
          </h1>
          <h1 className="header__description header__description--left">
            <div className="p-3">
              Sanda Tireda aspires to provide a platform to enable self-reliance
              for the wider to enhance its social impact footprint. After the
              successful completion of our inaugural competition where cash
              prizes of N250,000, N500,000 and N1,000,000 were given to 3
              winners. <br></br>We are delighted to announce our next
              initiative, Uwar Gida Business Plan Competition (BPC) for Women
              Micro-Entrepreneurs. This business plan competition promises to be
              a rewarding experience. Sanda Tireda will provide training on how
              to run and grow your business and will provide seed funding and
              mentorship to the winners. Watch this space for the official
              announcement and application details.
            </div>
          </h1>
          <div className="hero__text-box hero__text-box--container">
            <p className="subheading">Development Service</p>
            <h1 className="heading-secondary heading-secondary--dark">
              The Uwar Gida Initiative
            </h1>
            <h1 className="header__description header__description--left">
              <div className="p-3">
                Our next initiative, the uwar gida business training, a two-day
                event curated by Sanda Tireda for women owned enterprises to
                teach them the difference between merely running a business and
                building a successful growth-oriented enterprise. The workshop
                will provide participants with practical tools to critically
                assess their personal and business competencies and proven
                strategies to improve, eventually equipping them with the right
                mind-set and motivation that will bring about concrete and
                measurable changes in their own business and delivered in hausa
                language.
              </div>
            </h1>
          </div>
          <p className="subheading margin-top">Community Service</p>
          <h1 className="heading-secondary heading-secondary--dark">
            Social Impact
          </h1>
          <div className="header__youtube">
            <div>
              <iframe
                width=""
                height="315"
                src="https://www.youtube.com/embed/lHoDFeIr3yc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div>
              <iframe
                width=""
                height="315"
                src="https://www.youtube.com/embed/jR7Az8eGLaE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div>
              <iframe
                width=""
                height="315"
                src="https://www.youtube.com/embed/tGLj6CftKJU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="p-3">
              <img
                width="100%"
                src={require('../images/sanda/IMG_9217.JPG')}
                height="315"
                alt=""
              />
            </div>
            <div className="p-3">
              <img
                width="100%"
                src={require('../images/sanda/IMG_9221.JPG')}
                height="315"
                alt=""
              />
            </div>
            <div className="p-3">
              <img
                width="100%"
                src={require('../images/sanda/IMG_9227 (1).JPG')}
                height="315"
                alt=""
              />
            </div>
          </div>

          <div className="hero__text-box hero__text-box--container">
            <h1 style={{marginBottom:-45, marginTop:60}} className="header__description header__description--left">
              <div className="p-3">
                Pictures from the just concluded Sanda tireda’s capacity
                building for sanda uwar gida ,a subnit of sanda tireda that
                eases access to credit facilities for small and medium
                enterprises. The model provides inventory on credit to small and
                medium enterprises and delivers to them speedily at no cost.
              </div>
            </h1>
            <div className="header__youtube">
              <div className="p-3">
                <img
                  width="100%"
                  src={require('../images/11.jpg')}
                  height="315"
                  alt=""
                />
              </div>
              <div className="p-3">
                <img
                  width="100%"
                  src={require('../images/22.jpg')}
                  height="315"
                  alt=""
                />
              </div>
              <div className="p-3">
                <img
                  width="100%"
                  src={require('../images/33.jpg')}
                  height="315"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
