import React from 'react'
import CarouselNew from './Card'
import ContactForm from './ContactForm'
import { partners } from './partners'
import { useNavigate } from 'react-router'
import { FiArrowRight } from 'react-icons/fi'

export default function Hero() {
  const navigate = useNavigate()

  return (
    <>
      <section className="section-heros">
        {/* <div className="center-flex">
          <p className="subheading center">Our Partners</p>
        </div> */}
        {/* <div id="logos-home-page">
          <div class="slider">
            <div class="slide-track">
              {partners.map((i, idx) => (
                <div class="slide">
                  <img
                    src={i.img}
                    key={idx}
                    alt=""
                    class="alignnone size-full wp-image-13024 header__logo--new"
                  />
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div className="hero__text-box">
          <p className="subheading margin-top-small">
            YOUR DISTRIBUTION PARTNER FOR NORTHERN NIGERIA
          </p>
          <h1 className="heading-secondary heading-secondary--dark">
            Sanda Tireda
          </h1>
          <h1 className="header__description  header__description--left margin-top-small p-4">
            Sanda Tireda Limited founded July 2021, Sanda Tireda Limited is a
            distribution and logistics company headquartered in Kano, Nigeria.
            Today, it has grown to include 130 direct employees and has created
            over 268 indirect jobs. The Sanda Tireda model bridges the gap in
            distribution by ensuring last mile solutions. Sanda Tireda’s
            portfolio includes both food and non-food household items. Its food
            portfolio consists of rice, flour, seasoning cubes, cooking oil,
            noodles, sweets, snacks, and infant food items. Non-food products
            include home and toilet cleaning products, hand sanitisers,
            detergents and laundry starch. Sanda Tireda is the trusted Northern
            Nigeria distribution partner for key manufacturers and trading
            companies such as Tropical Group Investment, Multipro, FAN/Danone,
            Perfetti and PZ Cussons to mention a few.
          </h1>
        </div>

        <div className="hero">
          <div className="hero__text-box">
            <div
              style={{
                fontSize: 25,
                textAlign: 'center',
              }}
            >
              <h1 className="heading-secondary heading-secondary--dark">
                Vision
              </h1>
              <div className="dddd">
                <h1 className="header__description  header__description--left margin-top-small">
                  To become a leading agent of development for sole traders and
                  businesses trading food and household consumer goods.
                </h1>
              </div>
            </div>
            <div
              style={{
                fontSize: 25,
                textAlign: 'center',

                marginTop: 100,
              }}
            >
              <h1 className="heading-secondary heading-secondary--dark">
                Mission
              </h1>
              <div className="dddd">
                <h1 className="header__description  header__description--left margin-top-small">
                  Creating an environment which motivates and empowers sole
                  traders, SME’s and businesses to thrive by providing direct
                  access to household consumer goods and services.
                </h1>
              </div>
            </div>
          </div>
        </div>
        <article class="services__color">
          <div className="card__container">
            <CarouselNew />
          </div>
        </article>
        <div className="card__container">
          <div className="header__grid header__grid--two header__grid--two--sided">
            {' '}
            <img
              className="header__grid--imgs--bigs header__grid--imgs--noalign "
              src={require('../images/app/map-transpaent-orange-outline.png')}
              alt=""
            />
            <div className="header__description header__description--left ">
              <div className="heading-tertiary">Kirana Frachise Network</div>
              <div className="p-5">
                Sanda Tireda operates a franchise network of retail
                neighbourhood stores (the “Kirana’s”) across the North West.
                Kirana is an Indian word used to describe “a small, usually
                family owned, shop selling groceries and other household
                sundries”.In this business, the Kirana store owner enters into
                an agreement with Sanda Tireda for his/her inventory needs.
                Depending on their credit worthiness and track record, Sanda
                Tireda may supply part of the stock on credit to the Kirana.
                This enables these small store owners to scale up while allowing
                Sanda Tireda, and its manufacturing partners, to increase their
                supplies directly to consumers. Sanda Tireda uses an in-house
                developed “app” to monitor and track the sales and inventory at
                each Kirana Store.
              </div>
            </div>
          </div>
        </div>

        {/* <!-- SERVICES SECTION - HAD TO USE ARTICLE BEFORE SECTION TAG TO SET A COLOR OVER THE CONTAINER PADDING  --> */}
        <article class="services__color margin-top">
          <section class="services">
            <div className="center-flex">
              <p className="subheading  margin-top center">
                We deliver results that satisfy
              </p>
              <h1 className="heading-secondary heading-secondary--dark">
                Services We Render
              </h1>
            </div>

            <div className="ddddd mt-4">
              <div className="p-5">
                <div class="header__description header__description--left services--title__secondary">
                  Sanda Kirana
                </div>
                <div className="header__description header__description--left">
                  A model that provides inventory on credit to the shop owners
                  and delivers directly to the stores. This initiative promotes
                  business growth through the provision of micro-credit facility
                  to under capitalised small retailers.
                  <button
                    style={{ marginLeft: '0.5px', marginTop: 10 }}
                    className="btn bbbtttnn"
                    onClick={() => navigate('/kirana')}
                  >
                    <div className="btn__text">Read more</div>
                    <FiArrowRight />
                  </button>
                </div>
              </div>
              <div>
                <div class="services--image__position">
                  <img
                    class="services--image"
                    src={require('../images/shop.jpg')}
                    alt="qr-code"
                  />
                </div>
              </div>
            </div>

            <div className="ddddd1">
              <div>
                <div class="services--image__position__2">
                  <img
                    class="services--image"
                    src={require('../images/sanda/gida.jpg')}
                    alt="customer"
                  />
                </div>
              </div>
              <div className="p-5">
                <div class="header__description header__description--left services--title__secondary">
                  Sanda Uwargida
                </div>
                <div className="header__description header__description--left">
                  Similar to Kirana’s, this business unit is a women
                  cooperative-based initiative that identifies at home traders
                  and provides goods on credit for onward sales to house wives.
                  This creates business opportunities for women from the comfort
                  of their homes hence the name ‘Uwargida’ meaning mother of the
                  house.
                  <button
                    style={{ marginLeft: '0.5px', marginTop: 10 }}
                    className="btn bbbtttnn"
                    onClick={() => navigate('/uwargida')}
                  >
                    <div className="btn__text">Read more</div>
                    <FiArrowRight />
                  </button>
                </div>
              </div>
            </div>

            <div className="ddddd">
              <div className="p-5">
                <div class="header__description header__description--left services--title__secondary">
                  Sanda Direba
                </div>
                <div className="header__description header__description--left">
                  An initiative easing logistics and commuting within the Kano
                  metropolis through an affordable, comfortable and culturally
                  sensitive transportation system.
                  <button
                    style={{ marginLeft: '0.5px', marginTop: 10 }}
                    className="btn bbbtttnn"
                    onClick={() => navigate('/sandadireba')}
                  >
                    <div className="btn__text">Read more</div>
                    <FiArrowRight />
                  </button>
                </div>
              </div>
              <div>
                <div class="services--image__position__3">
                  <img
                    class="services--image"
                    src={require('../images/sanda/andeideita.jpg')}
                    alt="cards"
                  />
                </div>
              </div>
            </div>

            <div className="ddddd1">
              <div>
                <div class="services--image__position__2">
                  <img
                    class="services--image"
                    src={require('../images/sanda/new9.jpg')}
                    alt="customer"
                  />
                </div>
              </div>
              <div className="p-5">
                <div class="header__description header__description--left services--title__secondary">
                  Sanda Ruwan Gida
                </div>
                <div className="header__description header__description--left">
                  Seeks to solve the incessant water problem in Kano by
                  providing neighborhoods with clean and easily accessible water
                  to the last mile.
                  <button
                    style={{ marginLeft: '0.5px', marginTop: 10 }}
                    className="btn bbbtttnn"
                    onClick={() => navigate('/sandaruwangida')}
                  >
                    <div className="btn__text">Read more</div>
                    <FiArrowRight />
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="header__grid header__grid--twos">
              {/* <!-- STEP 01 --> */}

            {/* <div class="services--textbox">
                <div class="header__description header__description--left services--title__secondary">
                  Sanda Kirana
                </div>
                <div className="header__description header__description--left">
                  A model that provides inventory on credit to the shop owners
                  and delivers directly to the stores. This initiative promotes
                  business growth through the provision of micro-credit facility
                  to under capitalised small retailers.
                </div>
              </div>
              <div class="services--image__position">
                <img
                  class="services--image"
                  src={require('../images/sanda/store4.png')}
                  alt="qr-code"
                />
              </div>

              <!-- STEP 2 -->
              <div className="cccc">
                <div class="services--image__position__2">
                  <img
                    class="services--image"
                    src={require('../images/sanda/gida.jpg')}
                    alt="customer"
                  />
                </div>
                <div class="services--textbox">
                  <div class="header__description header__description--left services--title__secondary">
                    Sanda Uwargida
                  </div>
                  <div className="header__description header__description--left">
                    Similar to Kirana’s, this business unit is a women
                    cooperative-based initiative that identifies at home traders
                    and provides goods on credit for onward sales to house
                    wives. This creates business opportunities for women from
                    the comfort of their homes hence the name ‘Uwargida’ meaning
                    mother of the house.
                  </div>
                </div>
              </div>
              <!-- STEP 03 -->
              <div class="services--textbox">
                <div class="header__description header__description--left services--title__secondary">
                  Sanda Direba
                </div>
                <div className="header__description header__description--left">
                  An initiative easing logistics and commuting within the Kano
                  metropolis through an affordable, comfortable and culturally
                  sensitive transportation system.
                </div>
              </div>
              <div class="services--image__position__3">
                <img
                  class="services--image"
                  src={require('../images/sanda/andeideita.jpg')}
                  alt="cards"
                />
              </div>
              <!-- STEP 4 -->
              <div class="services--image__position__2">
                <img
                  class="services--image"
                  src={require('../images/sanda/new9.jpg')}
                  alt="customer"
                />
              </div>
              <div class="services--textbox">
                <div class="header__description header__description--left services--title__secondary">
                  Sanda Ruwan Gida
                </div>
                <div className="header__description header__description--left">
                  Seeks to solve the incessant water problem in Kano by
                  providing neighborhoods with clean and easily accessible water
                  to the last mile.
                </div>
              </div>
            </div> */}
          </section>
        </article>
        <div className="hero">
          <div className="hero__text-box">
            <div className=" center">
              <p className="subheading  center">
                We deliver results that satisfy
              </p>
              <h1 className="heading-secondary heading-secondary--dark">
                Testimonials
              </h1>

              <div className="header__description header__description--left header__description--right margin-top">
                Before registering with Sanda Tireda, I had to close my shop
                every time I needed to go to the market. Now I have expanded my
                business and even hired an extra person — Juliet
              </div>
            </div>
            <div className="margin-top"></div>
            <ContactForm />
          </div>
        </div>
      </section>
    </>
  )
}
