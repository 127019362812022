import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Modal } from 'reactstrap';
import './Contestant.css';
import { v4 as uuidv4 } from 'uuid';
import { AiOutlineCheck } from 'react-icons/ai';
// import { Modal } from 'reactstrap'
// import { useNavigate } from 'react-router'

function Contest() {
  const uniqueDeviceId = uuidv4();
  const [loading, setLoading] = useState(false);
  const [contestants, setContestants] = useState([]);
  const [userData, setUserData] = useState([]);
  const [modal, setModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [vote, setVote] = useState({
    phone_number: '',
    contestant_id: '',
  });
  const deviceId = localStorage.getItem('uniqueDeviceId');
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    if (deviceId) {
      getUserData(deviceId);
    } else {
      fetch(`https://galaxybis.ebudgetkano.ng/sanda-server/api/voter/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: uniqueDeviceId }),
      })
        .then((raw) => raw.json())
        .then((resp) => {
          console.log(resp);
          getUserData(uniqueDeviceId);
        })
        .catch((e) => {
          console.log(e);
        });

      localStorage.setItem('uniqueDeviceId', uniqueDeviceId);
    }
  }, [deviceId]);

  const getContestants = () => {
    setLoading(true);
    fetch('https://galaxybis.ebudgetkano.ng/sanda-server/api/get-contestants')
      .then((raw) => raw.json())
      .then((resp) => {
        console.log(resp);
        setLoading(false);
        console.log('somn');
        // if(resp.success){
        setContestants(resp['contestants']);
        // }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getContestants();
  }, []);

  const getUserData = (phoneNumber) => {
    fetch(
      `https://galaxybis.ebudgetkano.ng/sanda-server/api/voter/${phoneNumber}`,
    )
      .then((raw) => raw.json())
      .then((resp) => {
        // console.log(resp)
        if (resp.success) {
          setUserData(resp.voter);
        }
      })
      .catch((e) => {
        console.log(e, 'ddddddddddddd');
      });
  };

  const castVote = (id) => {
    // setLoading(true)
    // const uniqueDeviceId = localStorage.getItem('uniqueDeviceId')
    setModal(true);
    let voteData = {
      phone_number: userData?.phone_number,
      contestant_id: id,
    };
    fetch(`https://galaxybis.ebudgetkano.ng/sanda-server/api/vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(voteData),
    })
      .then((raw) => raw.json())
      .then((resp) => {
        // alert(resp.message)
        getUserData(userData.phone_number);
        setModal(false);
        console.log(resp);
        if (resp.message) {
          setSuccessModal(true);
        }
        setSuccessModal(false);
      })
      .catch((e) => {
        console.log(e);
        setModal(false);
      });
    // console.log(vote)
  };

  let votted = userData?.votted_for && userData?.votted_for !== '';

  const filteredContestants = [];
  contestants.forEach((c) => {
    if (c.name.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1) return;
    else filteredContestants.push(c);
  });
  const arrow = (
    <AiOutlineCheck
      size="4rem"
      style={{
        color: 'green',
        zIndex: 100,
        backgroundColor: '',
        margin: 0,
        padding: 0,
      }}
    />
  );
  return (
    <section className="section-hero--pattern">
      <div className="ccc" style={{ paddingTop: 200, marginBottom: -40 }}>
        <h1 className="heading-secondary heading-secondary--dark">
          Contestants
        </h1>
        {/* {JSON.stringify(userData)} */}
        <h1
          className="header__description  header__description--left margin-top-small"
          style={{ textAlign: 'center', margin: 0 }}
        >
          {/* header description, header description */}
        </h1>
      </div>
      {votted ? (
        <div className="noti_div">
          <div className="notification_">
            <h5 className="text-center m-0">Your vote has been casted!</h5>
          </div>
        </div>
      ) : null}
      <Row className="mb-5">
        <Col lg={1}></Col>
        <Col lg={10}>
          <div className="text-center">
            <input
              type="search"
              className="search__input mb-3"
              placeholder="Search Contestant Here..."
              value={searchTerm}
              onChange={({ target: { value } }) => setSearchTerm(value)}
            />
          </div>
          {loading ? (
            <div className="text-center">
              <span className="text-center">Loading contestants</span>
            </div>
          ) : (
            <Row style={{ margin: 0 }}>
              {filteredContestants.map((contestant) => {
                return (
                  <Col md={3}>
                    <Card className="cont_card p-4 shadow">
                      <p className="cont_name m-0">{contestant.name}</p>
                      <p className="cont_desc m-0">{contestant.description}</p>
                      {contestant?.id === parseInt(userData?.votted_for) ? (
                        <button className="cont_btn">{arrow}</button>
                      ) : (
                        <button
                          onClick={() => {
                            if (!votted) {
                              castVote(contestant?.id);
                            } else {
                              alert('User already votted!');
                            }
                          }}
                          className={votted ? 'striped-button ' : 'cont_btn'}
                          disabled={votted}
                        >
                          {votted ? 'You cannot vote' : 'Vote'}
                          {/* {contestant?.id === parseInt(userData?.votted_for)
                          ? arrow
                          : 'Vote'} */}
                        </button>
                      )}
                    </Card>
                  </Col>
                );
              })}
              <Modal isOpen={modal}>
                <div className="p-3 text-center">
                  <p className="m-0">Submitting your vote...</p>
                </div>
              </Modal>
              <Modal isOpen={successModal}>
                <div className="p-3 text-center">
                  <AiOutlineCheck style={{ color: 'green' }} size={50} />
                </div>
              </Modal>
            </Row>
          )}
        </Col>
        <Col lg={1}></Col>
      </Row>
    </section>
  );
}

export default React.memo(Contest);
