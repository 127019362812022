export const slides = [{
        city: "Sanda — Tireda",
        country: "Your Distribution Partner For Northern Nigeria",
        img: require("../images/app/new/sandatireda-building.png"),
    },
    {
        city: "Sanda — Kirana",
        country: "empowering micro, small and medium enterprises ",
        img: require("../images/app/new/shop.jpg"),
        readmore: "/kirana",
    },
    {
        country: "empowering women-owned enterprises ",
        city: "Sanda —  Uwargida",
        img: require("../images/app/new/gida.jpg"),
        readmore: "/uwargida",
    },
    {
        country: "Providing reliable and cost effective transportation",
        city: "Sanda — Direba",
        img: require("../images/app/new/bus.jpg"),
        readmore: "/sandadireba",
    },
    {
        country: "Water for All!",
        city: "Sanda — Ruwangida",
        img: require("../images/app/new/ruwa2.jpg"),
        readmore: "/sandaruwangida",
    },
];