import React from "react";
import { Outlet } from "react-router";
import Banner from "./component/Banner";
import Footer from "./component/Footer";
import Header from "./component/Header";

export default function AppIndex() {
  return (
    <div>
      <Banner />
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}
