import React from 'react'
import { FiArrowRight } from 'react-icons/fi'
import { useNavigate } from 'react-router'

export default function Competition() {
  const navigate = useNavigate()
  return (
    <section className="section-hero section-hero--pattern">
      <div className="hero">
        <div className="hero__text-box hero__text-box--container">
          <p className="subheading">Competition</p>
          <h1 className="heading-secondary heading-secondary--dark">
            Gasar fasahar sandatireda karo na biyu 2023
          </h1>
          <h1 className="header__description header__description--left">
            <div className="p-3">
              We are delighted to announce our next initiative, gassar fasahar
              sandatireda second edition 2023. Details below:
            </div>
            <div className="d-flex justify-content-center mt-4 mb-3">
              <button className="btn" onClick={() => navigate('/contest')}>
                <div className="btn__text">Cast your vote</div>
                <FiArrowRight />
              </button>
            </div>
          </h1>
          <div style={{ textAlign: 'center' }}>
            <img
              src={require('../images/gasa.jpg')}
              alt=""
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
