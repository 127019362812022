import React from 'react'
import OurPartner from './OurPartner'

export default function Aboutus() {
  return (
    <section className="section-hero section-hero--pattern">
      <div className="hero">
        <div className="hero__text-box">
          <p className="subheading margin-top-small">
            YOUR DISTRIBUTION PARTNER FOR NORTHERN NIGERIA
          </p>
          <h1 className="heading-secondary heading-secondary--dark">
            Sanda Tireda
          </h1>
          <h1 className="header__description  header__description--left margin-top-small">
            Sanda Tireda Limited founded July 2021, Sanda Tireda Limited is a
            distribution and logistics company headquartered in Kano, Nigeria.
            Today, it has grown to include 130 direct employees and has created
            over 268 indirect jobs. The Sanda Tireda model bridges the gap in
            distribution by ensuring last mile solutions. Sanda Tireda’s
            portfolio includes both food and non-food household items. Its food
            portfolio consists of rice, flour, seasoning cubes, cooking oil,
            noodles, sweets, snacks, and infant food items. Non-food products
            include home and toilet cleaning products, hand sanitisers,
            detergents and laundry starch. Sanda Tireda is the trusted Northern
            Nigeria distribution partner for key manufacturers and trading
            companies such as Tropical Group Investment, Multipro, FAN/Danone,
            Perfetti and PZ Cussons to mention a few.
          </h1>

          <div className="hero">
            <div className="hero__text-box">
              <div
                style={{
                  // fontSize: 25,
                  textAlign: 'center',
                  // marginLeft: 100,
                  // marginRight: 100,
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Vision
                </h1>
                <h1 className="header__description  header__description--left margin-top-small">
                  To become a leading agent of development for sole traders and
                  businesses trading food and household consumer goods.
                </h1>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  // marginLeft: 100,
                  // marginRight: 100,
                  marginTop: 100,
                }}
              >
                <h1 className="heading-secondary heading-secondary--dark">
                  Mission
                </h1>
                <h1 className="header__description  header__description--left margin-top-small">
                  Creating an environment which motivates and empowers sole
                  traders, SME’s and businesses to thrive by providing direct
                  access to household consumer goods and services.
                </h1>
              </div>
            </div>
          </div>
          <OurPartner />
        </div>
      </div>
    </section>
  )
}
