import React from "react";

export default function ContactForm() {
  return (
    <div className=" form form-grid">
      <div className="form__text">
        <p className="subheading">Get in touch</p>
        <h1 className="heading-secondary heading-secondary--dark left heading-secondary--dark--reduced">
          Contact our support team
        </h1>
        <h1 className="header__description">
          Our company’s operations cover Kano, Jigawa, Katsina, Kaduna and Yobe
          States.
        </h1>
        <h1 className="header__description">
          Head Office: Lautai House, C5 Wudil Road, Kano State, Nigeria.
        </h1>
        <h1 className="header__description">Email: info@sandatireda.com</h1>
        <h1 className="header__description">
          Telephone Number: +234 (0) 809 7766 556
        </h1>
      </div>
      <div className="form__grid">
        <div className="form__flex">
          <div className="form__information"></div>
          <div className="">
            <h1 className="heading-tertiary--smalls form__message">
              Send Us a Message
            </h1>
            <form className="form__form">
              <p className="form__paragraph">
                <span className="form__control">
                  <input
                    type="email"
                    name="email"
                    size="40"
                    placeholder="Your Email"
                    aria-required="true"
                    aria-invalid="false"
                    className="form__input"
                  />
                </span>
              </p>{" "}
              <p className="form__paragraph">
                <span className="form__control">
                  <input
                    type="text"
                    name="name"
                    size="40"
                    placeholder="Your Name"
                    aria-required="true"
                    aria-invalid="false"
                    className="form__input"
                  />
                </span>
              </p>
              <p className="form__paragraph">
                <div className="form__textarea-wrap">
                  <span className="form__control">
                    <textarea
                      name="message"
                      cols="40"
                      rows="10"
                      placeholder="Your Message"
                      // aria-required="true"
                      className="form__textarea"
                      aria-invalid="false"
                    ></textarea>
                  </span>
                </div>
              </p>
              <p className="form__paragraph">
                <div className="form__submit-wrap">
                  <span className="form__control">
                    <input
                      type="submit"
                      value="Send"
                      className="form__submit"
                    />
                  </span>
                </div>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
