import React from "react";
import { useRoutes } from "react-router-dom";
import AppIndex from "./AppIndex";
import Aboutus from "./component/Aboutus";
import Home from "./component/Home";
import NewBlog from "./component/News-Blog.jsx";
import Gallery from "./component/Gallery.jsx";
// import OurPartner from "./component/OurPartner";
import Kirana from "./component/Kirana";
import UwarGida from "./component/UwarGida";
import SandaDireba from "./component/SandaDireba";
import SandaRuwangida from "./component/SandaRuwangida";
import CSR from "./component/CSR";
import KiranaMap from "./component/KiranaMap";
import ContactUs from "./component/ContactUs";
import PrivacyPolicy from "./component/PrivacyPolicy";
import Contest from "./component/Contest";
import Competition from "./component/competition";

export default function AppNavigation() {
    const element = useRoutes([{
        path: "/",
        element: < AppIndex />,
        children: [
            { index: true, element: < Home /> },
            { path: "/about-us", element: < Aboutus /> },
            { path: "/news-blog", element: < NewBlog /> },
            { path: "/gallery", element: < Gallery /> },
            // { path: "/ourpartners", element: <OurPartner /> },
            { path: "/kirana", element: < Kirana /> },
            { path: "/uwargida", element: < UwarGida /> },
            { path: "/sandadireba", element: < SandaDireba /> },
            { path: "/sandaruwangida", element: < SandaRuwangida /> },
            { path: "/csr", element: < CSR /> },
            { path: "/kiranamap", element: < KiranaMap /> },
            { path: "/contactus", element: < ContactUs /> },
            { path: "/privacy-policy", element: <PrivacyPolicy /> },
            { path: "/contest", element: <Contest /> },
            { path: "/competition", element: <Competition /> },
        ],
    },]);
    return element;
}