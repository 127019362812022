import React from "react";
import { IoMailSharp, IoCallSharp } from "react-icons/io5";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

export default function Banner() {
  return (
    <div className="header__banner">
      <div className="header__banner-items">
        <div className="header__phone-email">
          <div className="header__phone">
            <IoCallSharp /> +234(0) 809 7766 556
          </div>
          <div className="header__email">
            <IoMailSharp />
            info@sandatireda.com
          </div>
        </div>
        <div className="header__socials">
          <FaFacebookF />
          <FaTwitter />
          <FaInstagram />
        </div>
      </div>
    </div>
  );
}
